import React from 'react'
import styled from 'styled-components'
import { Link, textContainer } from './shared'

const Container = styled.footer`
  border-top: 4px solid var(--accent-alt);
  background-color: var(--bg-accent);
  color: var(--fg-accent);
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;

  @media (min-width: 700px) {
    text-align: left;
  }
`

const TextBox = styled.div`
  ${textContainer}
  
  @media (min-width: 700px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  p {
    margin: 0;
    padding: 0;
    line-height: 1.5;
  }
`

const Info = styled.div`
  margin-bottom: 20px;
  @media (min-width: 700px) {
    margin-bottom: 0;
  }
`

const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <Container>
      <TextBox>
        <Info>
          <p>Sarah Gramm Wolf, M. Ed.</p>
          <p>SW Portland and N Salem Metro Area, Oregon</p>
          <p><Link href="mailto:garden.heirlooms@gmail.com">garden.heirlooms@gmail.com</Link></p>
          <p><Link href="tel:15033608086">503-360-8086</Link></p>
        </Info>
        <p>&copy; 2022{currentYear === 2022 ? '' : ` - ${currentYear}`}. Website by <Link href="https://www.arielkaplan.com" rel="noreferrer noopener" target="_blank">Ariel Kaplan</Link></p>
      </TextBox>
    </Container>
  )
}

export default Footer
