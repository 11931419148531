import React from 'react'
import styled from 'styled-components'
import { Link, textContainer } from './shared'

const Container = styled.div`
  ${textContainer}
  margin-bottom: 80px;
`

const About = () => {
  return (
    <Container>
      {/* "Let the beauty of what you love be what you do." —Rumi */}
      {/* <h2>My Story</h2>
      Prior to 2015, Sarah practiced foot reflexology, massage, and spiritual direction.  She accumulated 750 hours of continuing education beyond the 600-hour massage therapy program of studies.  And, during her two years at Akron General Medical Center (Ohio) as a chaplain, Sarah  added to her understanding of grief, trauma, coma, and caregiver pain as she earned four units of clinical pastoral education  in their hospital chaplaincy program.  It was during this time that she was credentialed as a minister with the Federation of Christian Ministries.

      Since moving to Oregon, Sarah has followed her interests in natural after-death care, connecting with our ancestors, walking the trails and parks in the Portland area, giving local presentations, and talking with people about how they remember family and friends who have died.

      With her sabbatical to Europe in 2015, a different path began to emerge.  While in southern France, she completed a residential Plant Medicine apprenticeship.  She also went to ancient sites, lived among the people in Romania to experience living on the land, being in nature, and walked World War I and II battlefields and cemeteries.  Since returning, she has completed the Oregon State University Extension Master Gardener program.  Sarah takes every opportunity to walk through different gardens, locally and when on travel, taking pictures of the flowers and plants, and talking with the gardeners.  After a tour of the Fort Vancouver Gardens, Sarah  dived into learning about heirloom plants, the pioneers and their struggles across the Oregon Trail, and planting heirloom or open-pollinated seeds and harvesting their seeds for sowing the following year.  She gives hands-on workshops on Sowing, Harvesting & Saving Heirloom Seeds.

      In 2017, the WCMGA offered Sarah the opportunity of  lead gardener for restoring the Jenkins Estate's Pioneer Herb Garden.  It has been a collaboration with other master gardeners in Oregon and community volunteers and visitors.   She also combines her experience as a hospital chaplain with her gardening expertise to create memory gardens for individuals who are grieving the loss of a pet.

      Sarah has expanded her horizons with her experience in restoring the medicinal, culinary, industrial, shade and aromatic beds within the Pioneer Herb Garden.   She is in the planning stages of creating a medicinal tea garden.  Stay tuned for developments.   In the meantime, please continue to check the WCMGA web site for a list of on-line and in-the-garden series classes.  They are held at both Jenkins and PCC Rockcreek.  Need a breath of fresh air?  Come, walk the trails throughout the property, sit and take in the ever-changing landscape, or browse through the gardens and see what is happening!

      Sarah Gramm Wolf, M. Ed. */}
      <h2>Credentials</h2>
      <ul>
        <li>Member, <Link inverse href="https://washingtoncountymastergardeners.org" target="_blank" rel="noreferrer noopener">Washington County Master Gardener Association</Link></li>
        <li>Oregon State University Extension Service Master Gardener since 2017</li>
        <li>Julia Graves' 10-day Residential Herbal Medicine Course in Chambert France</li>
        <li>Hospital chaplain, with 4 units of Clinical Pastoral Education at Akron General Medical center, Akron, OH</li>
        <li>Director/owner of Little Acorn Patch, Child Day Care Center, Springfield, VA</li>
        <li>M.Ed., George Mason University, Fairfax, VA</li>
      </ul>
    </Container>
  )
}

export default About
