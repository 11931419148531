import styled, { css } from 'styled-components'
import oak from '../images/oak1.svg'
import oakLg from '../images/oak2.svg'

export const textContainer = css`
  max-width: 800px;
  margin: auto;
  padding-left: 5vw;
  padding-right: 5vw;
`

export const Divider = styled.hr<{ isLarge?: boolean}>`
  position: relative;
  z-index: 1; // over subsequent sections
  height: 100px;
  margin-top: -100px;
  margin-bottom: 0;
  transform: translateY(45%);
  background-color: var(--accent);
  mask-image: url(${({ isLarge }) => isLarge ? oakLg : oak});
  mask-size: 100px;
  mask-repeat: no-repeat;
  mask-position: center;
  pointer-events: none;
`

export const Link = styled.a<{ inverse?: boolean }>`
  transition: color 0.2s ease-in-out;
  color: ${({ inverse }) => inverse ? 'var(--accent)' : 'var(--accent-alt)'};
  &:hover, &:active, &:focus {
  color: ${({ inverse }) => inverse ? 'var(--fg)' : 'var(--fg-accent)'};
  }
`
