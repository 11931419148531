import React from 'react'
import styled from 'styled-components'
import { SlCallOut, SlPaperPlane } from "react-icons/sl";
import profile from '../images/pioneer-profile.jpg';
import { Divider, textContainer } from './shared';

const Container = styled.div`
  border-bottom: 4px solid var(--accent-alt);
  background-color: var(--bg-accent);
  color: var(--fg-accent);
`

const TextBox = styled.div`
  ${textContainer}
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
  
  @media (min-width: 700px) {
    flex-direction: row;
    gap: 30px;
    text-align: left;
  }
  
  > * {
    flex: 1 1 auto;
  }
`

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  @media (min-width: 700px) {
    justify-content: left;
    text-align: left;
  }
`

const Button = styled.a`
  transition: border-color 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 3px;
  border: 1px solid rgba(255,255,255, 0.2);
  background-color: var(--accent);
  padding-left: 15px;
  padding-right: 15px;
  line-height: 2.5;
  font-size: 1.2rem;
  font-weight: 700;
  color: var(--fg-accent);
  text-decoration: none;
  &:hover, &:active, &:focus {
    border-color: var(--white);
    color: var(--fg-accent);
  }
`

const Image = styled.div.attrs({ role: 'img' })`
  border: 1px solid rgba(255,255,255, 0.2);
  border-radius: 3px;
  flex: 0 1 100%;
  width: 100%;
  max-width: 400px; // intrinsic width
  min-width: 200px;
  padding-top: 40%;
  background: var(--accent) url(${profile}) center/cover no-repeat;
  @media (min-width: 700px) {
    flex: 0 1 50%;
  }
`

const Contact = () => {
  return (
    <Container>
      <TextBox>
        <Image aria-label="Sarah in the Pioneer Herb Garden" />
        <div>
          <h1>Sarah Gramm Wolf, M. Ed.</h1>
          <p>Master Gardener and Educator</p>
          <ButtonGroup>
            <Button href="mailto:garden.heirlooms@gmail.com"><SlPaperPlane /> Email</Button>
            <Button href="tel:15033608086"><SlCallOut /> Call</Button>
          </ButtonGroup>
        </div>
      </TextBox>
      <Divider isLarge />
    </Container>
  )
}

export default Contact
