import React from 'react'
import styled from 'styled-components'
import { Divider, textContainer } from './shared'

const Container = styled.div`
  ${textContainer}
  margin-top: 80px;
  margin-bottom: 50px;
`

const Event = styled.div`
  &:not(:first-child) {
    margin-top: 40px;
  }
  &:last-of-type {
    padding-bottom: 20px;
  }
`

const Events = () => {
  return (
    <Container>
      <h2>Workshops & Classes</h2>
      <Event>
        <h3>Sowing, Harvesting and Saving Heirloom Seeds</h3>
        <p>A hands-on approach to saving heirloom seeds.  Specifically, wet and dry methods of harvesting, cleaning, drying, and storing saved seeds for maximum viability.</p>
        <p>Pioneers brought their saved seeds with them when they crossed the Oregon Trail… the tiny, mighty seed!</p>
        <p><i>1.5 hours</i></p>
      </Event>
      {/* <Event>
        <h3>Tools:  Prepping for Gardening</h3>
        <p>Clean garden tools work more effectively, are easier to use, and last longer.  Keeping blades clean and sharp improves cutting, which is easier on you and the plants.  Learn environmentally friendly cleaning, sharpening,  lubricating, and sanitizing techniques.   Shovel, Draw Hoe, Pruners, and Hand Pruning Saw demonstration.</p> 
        <p>Bring a garden tool to clean and sharpen.</p>
        <p><i>1 hour</i></p>
      </Event> */}
      <Event>
        <h3>Growing Your Own Herbal Teas</h3>
        <p>How to grow your favorite herbal teas in containers. Lavender, Roses, Lemon Verbena, Sage, Valerian, Thyme, & others. What parts of the herb to harvest, and how to brew the best tea. A great way to start planning for or adding to your own garden areas.</p>
        <p><i>1.5 hours</i></p>
      </Event>
      <Event>
        <h3>Be Water Wise</h3>
        <p>Explore low-cost ways of using less water in your home garden. Choose plants that have low water needs. Build healthy soil to hold more water. Use stand-alone watering containers, such as unglazed clay pots, plant nanny, tree bladder, and re-purposed containers to target the water to specific plants. Learn about plants that are heavy water users and consider options for reducing water consumption in your home garden. Explore pros and cons of rain-water harvesting.</p>
        <p><i>1 hour</i></p>
      </Event>
      <Event>
        <h3>Basic Garden Tools for the Home Gardener</h3>
        <p>New to gardening?  Overwhelmed by the choices available?  What is the best tool for the job?  Best tool for physical body self care?  This class examines basic tools to get your  garden jobs done:   Bypass pruners, garden spade, 4-prong rake, Hori-Hori  knife, collinear hoe, digging fork and other hand tools.</p>
        <p><i>1 hour</i></p>
      </Event>
      <Divider />
    </Container>
  )
}

export default Events
