import React from 'react'
import Footer from './components/Footer'
import About from './components/About'
import Events from './components/Events'
import Contact from './components/Contact'
import { Divider } from './components/shared'

function App() {
  return (
    <div>
      <header>
        <Contact />
      </header>
      <main>
        <Events />
        <About />
        <Divider isLarge />
      </main>
      <Footer />
    </div>
  );
}

export default App;
